// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';
import NodeLink from '~plugins/prismic/components/NodeLink';
import usePrismicPrivacyPolicy from '~plugins/prismic-privacy-policy/hooks/usePrismicPrivacyPolicy';

import type { Props, MailchimpFormValues } from './types';
import messages from './messages';
import styles from './styles';
import useContactFormInfo from './useContactFormInfo';

const defaultValues = { email: '', acceptPrivacyPolicy: false };
const defaultErrors = { email: '', acceptPrivacyPolicy: false };

const ContactForm = ({
  initialValues = defaultValues,
  onSuccess,
  onError,
  classes,
  className,
  ...props
}: Props) => {
  const intl = useIntl();
  const privacyPolicy = usePrismicPrivacyPolicy();
  const formInfo = useContactFormInfo();

  const [values, setValues] = React.useState<MailchimpFormValues>(
    initialValues,
  );
  const [errors, setErrors] = React.useState<MailchimpFormValues>(
    defaultErrors,
  );

  const resetForm = React.useCallback(
    (newValues?: MailchimpFormValues = defaultValues) => {
      setValues(newValues);
      setErrors(defaultErrors);
    },
    [],
  );

  const handleChange = React.useCallback(
    // $FlowFixMe --> Reason: flow types conflict
    (event: SyntheticInputEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.target;
      setValues(prevValues => ({ ...prevValues, [`${name}`]: value }));
    },
  );

  const handleSubmit = React.useCallback(
    (event: Event) => {
      event.preventDefault();
      const { email, ...rest } = values;
      addToMailchimp(email, rest)
        .then(({ msg: message, result }) => {
          const formProps = {
            message,
            values,
            resetForm,
            setErrors,
          };

          if (result === 'success' && onSuccess) {
            onSuccess(formProps);
          } else if (onError) {
            onError(formProps);
          }
        })
        .catch(() => {
          // do nothing, as addToMailchimp will never throw
        });
    },
    [values],
  );

  return (
    <form
      {...props}
      id="contact-form"
      onSubmit={handleSubmit}
      messages={messages}
    >
      <div className={classnames(className, classes.root)}>
        <InputBase
          type="email"
          name="email"
          value={values.email}
          placeholder={
            formInfo?.data?.label_email?.text ||
            intl.formatMessage(messages.labels.email)
          }
          onChange={handleChange}
          error={!!errors.email}
          inputProps={{ 'aria-label': 'Email input' }}
          className={classes.formField}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                aria-label="Send form button"
                className={classes.formButton}
              >
                {formInfo?.data?.label_submit?.text || (
                  <FormattedMessage {...messages.labels.submit} />
                )}
              </Button>
            </InputAdornment>
          }
        />
        {errors.email ? (
          <FormHelperText component="div" className={classes.helperText}>
            <RichText html={errors.email} />
          </FormHelperText>
        ) : (
          undefined
        )}
        <FormControlLabel
          control={
            <Checkbox required onChange={handleChange} value="checkbox" />
          }
          labelPlacement="end"
          label={
            hasRichTextFieldValue(
              formInfo?.data?.label_accept_privacy_policy,
            ) ? (
              <RichText {...formInfo?.data?.label_accept_privacy_policy} />
            ) : (
              <FormattedMessage
                {...messages.labels.acceptPrivacyPolicy}
                values={{
                  link: privacyPolicy ? (
                    <NodeLink target="_blank" node={privacyPolicy}>
                      <FormattedMessage {...messages.labels.privacyPolicy} />
                    </NodeLink>
                  ) : (
                    <FormattedMessage {...messages.labels.privacyPolicy} />
                  ),
                }}
              />
            )
          }
          className={classes.formPrivacyPolicy}
          aria-label={intl.formatMessage(messages.labels.acceptPrivacyPolicy, {
            link: intl.formatMessage(messages.labels.privacyPolicy),
          })}
        />
      </div>
    </form>
  );
};

ContactForm.defaultProps = {
  className: undefined,
  initialValues: undefined,
  onSuccess: undefined,
  onError: undefined,
};

export default withStyles<*, *, Props>(styles)(ContactForm);
