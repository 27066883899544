// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '../../prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicPrivacyPolicy } from '~schema';

const query = graphql`
  query UsePrismicPrivacyPolicyDefaultQuery {
    allPrismicPrivacyPolicy {
      edges {
        node {
          id
          prismicId
          type
          lang
          slugs
          data {
            meta_title {
              text
            }
            meta_description {
              text
            }
            meta_keywords {
              meta_keyword {
                text
              }
            }
            title {
              text
            }
            page_title {
              text
            }
            page_description {
              text
              html
            }
          }
        }
      }
    }
  }
`;

export default function usePrismicPrivacyPolicy(): ?PrismicPrivacyPolicy {
  return useFindCurrentLocaleNode<PrismicPrivacyPolicy>({
    nodes: useStaticQuery<Query>(query).allPrismicPrivacyPolicy?.nodes,
    toQuery: prismicPrivacyPolicy => ({ prismicPrivacyPolicy }),
    fromQuery: data => data?.prismicPrivacyPolicy,
  });
}
