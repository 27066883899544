// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  formField: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    border: `2px solid ${theme.palette.common.white}`,
  },
  formButton: {
    height: 37,
    minWidth: 116,
    color: 'inherit',
  },
  formPrivacyPolicy: {
    marginTop: theme.spacing(1),
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  helperText: {
    ...theme.typography.body2,
    color: theme.palette.secondary.main,
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
});

export default styles;
