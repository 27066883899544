// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import type { MailchimpFormValues } from './types';
import messages from './messages';
import type { PrismicContact } from '~schema';

const useContactFormValidationSchema = (node: ?PrismicContact) => {
  const intl = useIntl();
  return React.useMemo(
    () =>
      yup.object<MailchimpFormValues>({
        email: yup
          .string()
          .required(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.required),
          )
          .email(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.email),
          ),
        acceptPrivacyPolicy: yup
          .boolean()
          .test(
            'isTrue',
            node?.data?.error_must_accept_privacy_policy?.text ||
              intl.formatMessage(messages.errors.mustAcceptPrivacyPolicy),
            value => value === true,
          ),
      }),
    [node],
  );
};

export default useContactFormValidationSchema;
