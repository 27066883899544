// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import ContactForm from '~components/ContactForm';
import useContactFormValidationSchema from '~components/ContactForm/useContactFormValidationSchema';
import RichText from '~plugins/prismic/components/RichText';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import type { PrismicContact } from '~schema';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicContact>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {},
});

const ContactPage = ({ classes, node }: Props) => (
  <Container component={ResPadding} vertical className={classes.root}>
    {node?.data?.title?.text ? (
      <Typography variant="h4" gutterBottom>
        {node?.data?.title?.text}
      </Typography>
    ) : null}
    <RichText {...node?.data?.description} />
    <ContactForm
      name="contact"
      node={node}
      validationSchema={useContactFormValidationSchema(node)}
    />
  </Container>
);

export default compose(
  withNodePage<PrismicContact, *>({
    getNode: data => data?.prismicContact,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(ContactPage);

export const query = graphql`
  query PrismicContactPage($prismicId: ID) {
    prismicContact(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
        description {
          text
          html
        }
        label_email {
          text
        }
        label_accept_privacy_policy {
          text
          html
        }
        label_submit {
          text
        }
        error_required {
          text
        }
        error_email {
          text
        }
        error_must_accept_privacy_policy {
          text
        }
        event_success {
          text
          html
        }
        event_error {
          text
          html
        }
        event_reject {
          text
          html
        }
      }
    }
  }
`;
